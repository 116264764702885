@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", system-ui, sans-serif;
  }

  select,
  option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

@layer components {
  .btn-primary {
    @apply m-2 text-primary-blue text-sm flex gap-2 items-center border border-primary-blue p-2 rounded-lg justify-center disabled:text-gray-400 disabled:border-gray-400;
  }

  .btn-secondary {
    @apply m-2 text-red-700 text-xs flex gap-2 items-center border border-red-700 p-2 rounded-lg justify-center disabled:text-gray-400 disabled:border-gray-400;
  }

  .left-drawer-item {
    @apply flex items-center justify-center h-16 gap-2;
  }

  .selected-left-drawer-item {
    @apply flex items-center justify-center h-16 gap-2 bg-black bg-opacity-5;
  }

  .icon {
    @apply text-secondary-gray;
  }

  .input {
    @apply block w-full p-4 border border-gray-300 rounded-md bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 disabled:opacity-70;
  }

  .input-with-error {
    @apply block w-full p-4 border border-gray-300 rounded-md bg-gray-50 text-base focus:ring-red-700 focus:border-red-700;
  }

  .label {
    @apply block mb-2 text-sm font-medium text-gray-900;
  }

  .btn-disabled {
    @apply m-2 text-secondary-gray text-sm flex gap-2 items-center border border-secondary-gray p-2 rounded-lg justify-center opacity-70 cursor-default;
  }

  .signin-btn {
    @apply w-full bg-primary-blue text-white focus:ring-4 focus:ring-blue-500 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center;
  }

  .progress {
    animation: progress 1s infinite linear;
  }

  .left-right {
    transform-origin: 0% 50%;
  }

  .selected-item {
    @apply bg-[#ecedee]
  }

  .select-patient {
    @apply block w-full border border-gray-300 rounded-md bg-gray-50 text-base p-4;
  }

  @keyframes progress {
    0% {
      transform: translateX(0) scaleX(0);
    }

    40% {
      transform: translateX(0) scaleX(0.4);
    }

    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }

  .HorizontalAppointment-title {
    @apply !whitespace-normal;
  }

  .title {
    @apply text-xl font-medium pb-2;
  }

  .subtitle {
    @apply font-medium pb-2;
  }

  .modal-content {
    @apply border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none;
  }

  .modal{
    @apply justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none;
  }

  .left-bar-open{
    @apply bg-white shadow-sm fixed z-20 pt-12 top-0 md:z-10 left-0 transition-transform md:translate-x-0 -translate-x-0; 
  }

  .left-bar-closed{
    @apply bg-white shadow-sm fixed z-20 pt-12 top-0 md:z-10 left-0 transition-transform md:translate-x-0 -translate-x-full; 
  }
}